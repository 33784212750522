body {
  margin: 0;
  font-family: monospace, 'Courier New', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color:hsl(0, 0%, 20%);
}

button {
  font-family: monospace, 'Courier New', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

input {
  font-family: monospace, 'Courier New', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

a:link {
  color: hsl(0, 0%, 20%);
  text-decoration: none;
}

a:visited {
  color: hsl(0, 0%, 20%);
  text-decoration: none;
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: hsl(0, 0%, 74%) hsl(0, 0%, 90%);
}

/* Chrome */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: hsl(240, 4%, 95%);
}

*::-webkit-scrollbar-thumb {
  background-color: hsl(220, 2%, 74%);
  border-radius: 20px;
  border: 3px solid hsl(240, 4%, 95%);
}

/*
blue: #055eff or rgb(5, 94, 255)

pink: #ec3a88 or rgb(236, 58, 136)
ff479b  rgb(255, 71, 155)

*/
